const px = ['16px', '8px', '4px'];
const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`);

const image = {
  boxShadow: shadow.join(', '),
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
  maxWidth: '100%',
};

export default image;